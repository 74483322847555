import Vue from "vue"

import { library } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { fas } from "@fortawesome/free-solid-svg-icons"
import "@fortawesome/fontawesome-free/css/all.css"
import "@fortawesome/fontawesome-free/css/v4-shims.css"

library.add(fas)
Vue.component("fa", FontAwesomeIcon)
